<template>
  <div class="sign-filter">
    <!-- <div
      class="filter-box"
    >
      <ul class="filter-uls">
        <select-checher
          ref="selectChecher"
          v-model="filterData.registerStatus"
        />
      </ul>
    </div> -->
    <div
      v-if="$route.params.i !== '0'"
      class="filter-box"
    >
      <ul class="filter-ul">
        <select-picker
          :data="filterData.branchOrgIdList"
          @toTree="toBranchSelect"
        />
      </ul>
    </div>
    <div
      class="filter-box"
    >
      <ul
        class="filter-uls"
        style="margin-right: 0;"
        @touchstart="timeComponentsBool=true"
      >
        <li class="filter-item">
          <div class="filter-name">{{ filterData.filterTime.title }}</div>
          <div class="filter-value">
            <span class="filter-val">{{ timeValue }}</span>
            <i class="icon-joyo arrows">&#xe66c;</i>
          </div>
        </li>
      </ul>
    </div>
    <div
      v-if="!timeComponentsBool"
      class="bottom-postion"
    >
      <cube-button
        :outline="true"
        class="btn btns"
        @click="reset"
      >
        重置
      </cube-button>
      <cube-button
        class="btn btns"
        @click="confirm"
      >
        确认
      </cube-button>
    </div>
    <custom-popup
      ref="customPopups"
      :is-up="true"
    >
      <div
        slot="content"
        class="dialog-detail filter-box marginNo"
      >
        <ul class="filter-ul">
          <select-check
            v-for="(item, index) of filterData.branchOrgIdList.options"
            :key="index"
            :data="item"
            @click.native="subscriptOperat(index)"
          />
        </ul>
      </div>
      <div
        slot="button"
        class="bottom-div"
      >
        <div
          class="btn-div"
          @click="returns"
        >
          返回
        </div>
        <div
          class="btn-divs"
          @click="ensureBanch"
        >
          确定
        </div>
      </div>
    </custom-popup>
    <date-month
      v-if="timeComponentsBool"
      :index="filterData.filterTime.value.type"
      :value="filterData.filterTime.value.data"
      @assignTime="assignTime"
      @cacal="timeComponentsBool = false"
    ></date-month>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

// import selectChecher from '_c/checher';
import dateMonth from '_c/date-month';
import selectPicker from '_c/picker/select';
import selectCheck from '_c/picker/select-check';
import customPopup from '_c/popup/custom-popup';

export default {
  name: 'SignFilter',
  components: {
    dateMonth,
    selectPicker,
    selectCheck,
    // selectChecher,
    customPopup
  },
  data() {
    return {
      filterData: {
        // registerStatus: {
        //   title: '签到状态',
        //   name: '',
        //   value: '',
        //   index: 0,
        //   options: []
        // },
        branchOrgIdList: {
          title: '分公司',
          name: '',
          value: [],
          options: [],
          dap: false
        },
        filterTime: {
          title: '时间',
          name: '',
          value: {
            type: 0,
            data: {}
          }
        }
      },
      timeComponentsBool: false,
    };
  },
  computed: {
    ...mapGetters('sign', ['filter']),
    ...mapGetters(['branchList']),
    ...mapGetters(['dictList']),
    timeValue() {
      let value = ''
      if (Array.isArray(this.filterData.filterTime.value.data)) {
        value = this.filterData.filterTime.value.data.join(' 至 ')
      } else {
        value = this.filterData.filterTime.value.data.month || ''
      }
      return value
    }
  },
  watch: {
    branchList(newValue) {
      this.filterData.branchOrgIdList.options = newValue;
    }
  },
  mounted() {
    // 获取分公司
    this.getRegionFiliales(2);
    this.getOptions();
    this.getSellData();
  },
  methods: {
    ...mapMutations('sign', ['SET_FILTER']),
    ...mapMutations(['CLEAR_FILTER']),
    ...mapActions(['getRegionFiliales']),
    confirm() {
      this.cache();
      this.$router.go(-1);
    },
    reset() {
      for (const i in this.filterData) {
        this.filterData[i].name = '';
        if (i === 'branchOrgIdList') {
          this.filterData[i].value = [];
        } else if (i === 'filterTime') {
          this.filterData[i].value = {
            type: 0,
            data: {}
          }
        } else {
          this.filterData[i].value = '';
        }
      }
      this.CLEAR_FILTER('sign');
    },
    cache() {
      const filterData = {};
      for (const i in this.filterData) {
        filterData[i] = {
          name: this.filterData[i].name,
          value: this.filterData[i].value,
          index: this.filterData[i].index
        };
      }

      this.SET_FILTER(filterData);
    },
    toBranchSelect() {
      this.$refs.customPopups.isShow = true;
    },
    getSellData() {
      if (JSON.stringify(this.filter) !== '{}') {
        for (const i in this.filter) {
          if (i === 'branchOrgIdList') {
            this.filterData[i].name = this.filter[i].name;
            this.filterData[i].value = this.filter[i].value;
            if (this.filter[i].value.length) {
              this.filterData.branchOrgIdList.value.forEach(item => {
                this.filterData.branchOrgIdList.options.forEach(itemA => {
                  if (item === itemA.value) {
                    itemA.dep = true;
                  }
                });
              });
            } else {
              this.filterData[i].value = [];
            }
          } else {
            this.filterData[i].name = this.filter[i] ? this.filter[i].name : '';
            this.filterData[i].value = this.filter[i] ? this.filter[i].value : '';
            // if (i === 'registerStatus') {
            //   this.filterData[i].index = this.filter[i] ? this.filter[i].index : 0;
            // }
          }
        }
      }
    },
    getOptions() {
      this.filterData.branchOrgIdList.options = this.branchList;
      // this.filterData.registerStatus.options = [{ text: '全部', value: '' }, ...this.dictList.registerStatus.filter(item => item.value !== 1)];
    },
    subscriptOperat(index) {
      this.filterData.branchOrgIdList.options[index].dep = !this.filterData.branchOrgIdList.options[index].dep;
    },
    ensureBanch() {
      const list = [];
      this.filterData.branchOrgIdList.options.forEach(item => {
        if (item.dep === true) {
          list.push(item);
        }
      });
      this.filterData.branchOrgIdList.value = list.map(item => item.value);
      this.filterData.branchOrgIdList.name = list.map(item => item.text).join(',');
      this.cache();
      this.returns();
    },
    returns() {
      this.$refs.customPopups.isShow = false;
    },
    assignTime(value, timeIndex) {
      this.filterData.filterTime.value = {
        type: Number(timeIndex),
        data: value
      }
      this.timeComponentsBool = false
    }
  }
};

</script>

<style lang="scss" scoped  src="@/assets/css/components/btn-bottom.scss"></style>
<style lang="scss" scoped  src="@/assets/css/components/filter.scss"></style>
<style lang="scss" scoped >
.dialog-detail{
  z-index: 102;
  height: calc(100% - 46px);
  overflow-y: auto;
}
.marginNo{
  margin: 0
}
.sign-filter {
  .filter-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height:50px ;
    .filter-value{
      display: flex;
      flex:1;
      align-items: center;
      overflow: hidden;
      line-height: 24px;
      .filter-val{
        flex: 1;
        color:$color-333;
        font-size:$font-15;
        text-align: right;
        @extend .uniline-ellipsis;
      }
      .arrows{
        margin:0 15px 0 10px;
        color: $color-CCC;
        font-size: 11px;
      }
    }
  }
}
</style>
