<template>
  <div class="dateMonth-mask">
    <div class="dateMonth-box">
      <div class="dateMonth-tabr">
        <div
          v-for="(item, index) in tabList"
          :key="index"
          class="dateMonth-tabr-item"
          :class="pageIndex === index ? 'active' : ''"
          @click="toggle(index)"
        >
          <span>{{ item.val }}</span>
        </div>
      </div>
      <div class="dateMonth-time">
        <div
          v-if="pageIndex === 0"
          :class=" monthObj.month !== '' ? 'dateMonth-month' : 'dateMonth-ple'"
          @click="showTimePicker"
        >
          {{ monthObj.month ? monthObj.month : '当前月份' }}
        </div>
        <div
          v-if="pageIndex === 1"
          class="dateMonth-date"
        >
          <div
            :class="timeObj.startTime !== '' ? 'dateMonth-date-a' : 'dateMonth-pled'"
            @click="showDateSegmentPicker"
          >{{ timeObj.startTime ? timeObj.startTime : '开始日期' }}</div>
          <div class="dateMonth-zhi">至</div>
          <div
            :class="timeObj.endTime !== '' ? 'dateMonth-date-a' : 'dateMonth-pled'"
            @click="showDateSegmentPicker"
          >{{ timeObj.endTime ? timeObj.endTime : '结束日期' }}</div>
        </div>
      </div>
      <i class="wire"></i>
      <div class="bottom-postion btn-return-other">
        <cube-button
          :light="true"
          class="btn-return"
          @click="cacal"
        >
          取消
        </cube-button>
        <cube-button
          type="submit"
          class="btn-other"
          @click="sure"
        >
          确定
        </cube-button>
      </div>
    </div>
  </div>
</template>

<script>
const dateSegmentData = [
  {
    is: 'cube-date-picker',
    title: '开始时间',
    min: new Date(2000, 0, 1),
    max: new Date(2050, 11, 31),
    value: new Date()
  },
  {
    is: 'cube-date-picker',
    title: '结束时间',
    min: new Date(2000, 0, 1),
    max: new Date(2050, 11, 31),
    value: new Date()
  }
]

import { formatDateTime } from '../../lib/until';
export default {
  props: {
    index: {
      type: Number,
      default: 0
    },
    value: [Object, Array]
  },
  data() {
    return {
      pageIndex: 0,
      tabList: [{ val: '按月选择', key: 1 }, { val: '按日选择', key: 2 }],
      monthObj: {
        month: `${new Date().getFullYear()}-${new Date().getMonth() + 1}`,
        monthValue: [new Date().getFullYear(), new Date().getMonth() + 1]
      },
      timeObj: {
        startTime: '',
        startTimeValue: '',
        endTime: '',
        endTimeValue: ''
      },
      // 构造时间
      dateSegmentPicker: null
    }
  },
  created() {
    // 回显
    this.pageIndex = this.index
    if (Array.isArray(this.value)) {
      this.timeObj.startTime = this.value[0] || ''
      this.timeObj.endTime = this.value[1] || ''
      dateSegmentData[0].value = new Date((this.value[0] || '').replaceAll('-', '/'))
      dateSegmentData[1].value = new Date((this.value[1] || '').replaceAll('-', '/'))
    } else if (this.value.month) {
      this.monthObj = JSON.parse(JSON.stringify(this.value))
    }
  },
  mounted() {
    this.initTime();
    // this.showPicker()
  },
  methods: {
    toggle(index) {
      this.pageIndex = index
      // this.$nextTick(() => {
      //   this.showPicker()
      // })
    },
    cacal() {
      this.$emit('cacal')
    },
    // 影响体验先去掉
    // showPicker() {
    //   if (this.pageIndex === 0) {
    //     this.showTimePicker()
    //   } else {
    //     this.showDateSegmentPicker()
    //   }
    // },
    sure() {
      let value = null;
      if (this.pageIndex === 0) {
        value = this.monthObj;
        this.$emit('assignTime', value, this.pageIndex)
      } else {
        value = this.timeObj.startTime !== '' ? [this.timeObj.startTime, this.timeObj.endTime] : [];
        if (value.length) {
          this.$emit('assignTime', value, this.pageIndex)
        } else {
          this.cacal();
        }
      }
    },
    showTimePicker () {
      const value = new Date(this.monthObj.monthValue.join('/') + '/01')
      this.$createDatePicker({
        min: new Date(2000, 1),
        max: new Date(2050, 12),
        value: value,
        columnCount: 2,
        onSelect: (selectedTime, selectedText, formatedTime) => {
          this.monthObj.month = formatedTime.join('-');
          this.monthObj.monthValue = formatedTime;
        },
        onCancel: () => {

        },
        $class: {
          'date-month-com-picker': true
        }
      }).show()
    },
    // 开始时间-结束时间
    initTime() {
      this.dateSegmentPicker = this.$createSegmentPicker({
        data: dateSegmentData,
        onSelect: (selectedDates, selectedVals, selectedTexts) => {
          this.timeObj.startTime = formatDateTime(new Date(selectedTexts[0].join('/')));
          this.timeObj.endTime = formatDateTime(new Date(selectedTexts[1].join('/')));
        },
        onNext: (i, selectedDate) => {
          dateSegmentData[1].min = selectedDate
          if (i === 0) {
            this.dateSegmentPicker.$updateProps({
              data: dateSegmentData
            })
          }
        },
        $class: {
          'date-month-com-picker': true
        }
      })
    },
    showDateSegmentPicker() {
      this.dateSegmentPicker.show()
    }
  }
}

</script>
<style lang="scss" scoped src="@/assets/css/components/btn-bottom.scss"></style>
<style lang="scss" scoped>
.dateMonth-mask{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}
.dateMonth-box{
  position: absolute;
  bottom:0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 320px;
  background: $color-FFF;
  border-radius: 12px 12px 0 0;
  .dateMonth-tabr{
    display: flex;
    align-items: stretch;
    justify-content: space-around;
    height: 48px;
    border: 1px solid $color-F7;
    border-radius: 12px 12px 0 0;
    .dateMonth-tabr-item{
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      span{
        color: #999BA3;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
      }
      .dateMonth-underscore-active{
        width: 32px;
        height: 2px;
        margin-top: 12px;
        background: #00A4FF;
      }
      .dateMonth-underscore{
        width: 32px;
        height: 2px;
        margin-top: 12px;
        background: $color-FFF;
      }
    }
    .active {
      span{
        color: #00A4FF;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
      }
    }
    .active::after {
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 32px;
      height: 2px;
      background: #00A4FF;
      transform: translateX(-50%);
      content: " ";
    }
  }
  .dateMonth-time{
    height: 56px;
    margin-top: 48px;
    padding: 0 15px;
    .dateMonth-month{
      padding: 18px 0;
      color: #23252E;
      font-size: 15px;
      line-height: 20px;
      border-bottom: 1px solid $color-F7;
    }
    .dateMonth-date{
      display: flex;
      align-items: center;
      justify-content: space-around;
      .dateMonth-date-a{
        padding: 18px 27px;
        color: #23252E;
        font-size: 15px;
        line-height: 20px;
        border-bottom: 1px solid $color-F7;
      }
      .dateMonth-zhi{
        padding: 18px 15px;
        color: #999BA3;
        font-size: 15px;
        line-height: 20px;
      }
    }
  }
  .dateMonth-ple{
    color: #C6C7CC;
    font-size: 15px;
    line-height: 20px;
  }
  .dateMonth-pled{
    padding: 18px 34px;
    color: #C6C7CC;
    font-size: 15px;
    line-height: 20px;
    border-bottom: 1px solid $color-F7;
  }
}
.btn-return-other{
  padding-top: 10px;
  border-top: 1px solid $color-F7;
}

</style>

<style lang="scss">
.date-month-com-picker {
  .cube-popup-content {
    height: 320px;
    overflow: hidden;
    border-radius: 12px 12px 0 0;
  }
}

.cube-foos{
  height: 22px;
  color: #23252E !important;
  font-size: 16px !important;
  line-height: 22px;
}
</style>
